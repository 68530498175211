<template>
  <div class="flex flex-col gap-1">
    <div>
      <fw-label size="xs">Cenários de vídeo</fw-label>
      <div class="relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-4">
        <div v-if="obsServer['main'].isConnected" class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-4">
            <fw-button
              v-for="scene in obsServer['main'].scenes"
              :key="scene.name"
              :type="obsServer['main'].currentScene === scene.name ? 'link' : 'link-muted'"
              expanded
              size="xl"
              class="h-[5.5rem] text-2xl"
              :class="{ 'text-gray-400': obsServer['main'].currentScene !== scene.name }"
              @click.native="switchScene(obsServer['main'], scene.name)"
            >
              {{ scene.label }}
            </fw-button>
          </div>
        </div>
        <div
          v-else
          class="text-base text-center py-5"
          :class="{
            'text-gray-600': !obsServer['main'].error,
            'text-red-600': obsServer['main'].error
          }"
        >
          {{ !obsServer['main'].error ? 'Desligado' : obsServer['main'].error }}
        </div>
      </div>
    </div>
    <div v-if="usePresentationScreen">
      <fw-label size="xs">Controlo de ecrã principal</fw-label>
      <div class="relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-4">
        <div v-if="obsServer['presentation'].isConnected" class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-4">
            <fw-button
              v-for="scene in obsServer['presentation'].scenes"
              :key="scene.name"
              :type="obsServer['presentation'].currentScene === scene.name ? 'link' : 'link-muted'"
              expanded
              size="xl"
              :class="{ 'text-gray-400': obsServer['presentation'].currentScene !== scene.name }"
              @click.native="switchScene(obsServer['presentation'], scene.name)"
            >
              {{ scene.label }}
            </fw-button>
          </div>
        </div>
        <div
          v-else
          class="text-base text-center py-5"
          :class="{
            'text-gray-600': !obsServer['presentation'].error,
            'text-red-600': obsServer['presentation'].error
          }"
        >
          {{ !obsServer['presentation'].error ? 'Desligado' : obsServer['presentation'].error }}
        </div>
      </div>
    </div>
    <div v-if="obsServer['main'].isConnected">
      <fw-label size="xs">Gravação</fw-label>
      <div>
        <ButtonTablet
          :color="obsServer['main'].isRecording ? 'red' : 'inactive'"
          class="text-base w-full px-5 py-1 h-[4rem]"
          @click.native="toggleRecording"
        >
          <div
            class="flex items-center justify-between gap-2 w-full"
            :class="{
              'text-white animate-pulse': obsServer['main'].isRecording,
              'text-gray-400': !obsServer['main'].isRecording
            }"
          >
            <fw-icon-record-circle class="w-6 h-6" />
            <span>{{ obsServer['main'].isRecording ? 'A gravar...' : 'Gravar' }}</span>
          </div>
        </ButtonTablet>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTablet from '@/components/buttons/ButtonTablet'

export default {
  name: 'PanelObsControl',

  components: {
    ButtonTablet
  },

  data() {
    return {
      obsServer: {
        main: {
          name: 'main',
          ws: null,
          isConnected: false,
          error: null,
          config: {
            address: 'localhost',
            password: '...'
          },
          isVirtualCamOn: false,
          scenes: [
            { name: 'Main', label: 'Principal' },
            { name: 'Screen', label: 'Partilha' },
            { name: 'Audience', label: 'Plateia' }
          ],
          currentScene: null,
          isRecording: false
        },
        presentation: {
          name: 'presentation',
          ws: null,
          isConnected: false,
          error: null,
          config: {
            address: localStorage.getItem('obs-presentation-server-address') || null,
            password: null
          },
          scenes: [
            { name: 'Hall', label: 'Hall' },
            { name: 'Screen', label: 'Partilha' }
          ],
          currentScene: null
        }
      },
      usePresentationScreen: localStorage.getItem('obs-use-presentation-screen') === 'true'
    }
  },

  onDestroy() {
    this.obsServer['main'].ws.close()
    if (this.usePresentationScreen) {
      this.obsServer['presentation'].ws.close()
    }
  },

  mounted() {
    if (this.usePresentationScreen) {
      this.connectToObs('presentation')
    }
    this.connectToObs('main')
  },

  methods: {
    connectToObs(obsName) {
      var self = this
      var obs = this.obsServer[obsName]

      obs.ws = new WebSocket(`ws://${obs.config.address}:4455`)

      obs.ws.onopen = () => {
        obs.isConnected = true
        self.wsAuthenticate(obs)
      }

      obs.ws.onmessage = message => {
        self.wsHandleMessage(obs, JSON.parse(message.data))
      }

      obs.ws.onerror = error => {
        obs.error = error
        obs.isConnected = false
      }
    },

    wsAuthenticate(obs) {
      this.wsSendMessage(obs, {
        op: 1,
        d: { rpcVersion: 1, authentication: obs.config.password }
      })
    },

    wsSendMessage(obs, message) {
      if (obs.ws && obs.ws.readyState === WebSocket.OPEN) {
        obs.ws.send(JSON.stringify(message))
      } else {
        console.error('WebSocket is not open', obs)
      }
    },

    wsHandleMessage(obs, message) {
      console.log('Received OBS WS Message:', obs, message)

      // Initial request
      if (message.op === 0) {
        // Main OBS
        if (obs.name === 'main') {
          this.getVirtualCamStatus() // If not active, start it in the next message
          // this.stopRecording() // Make sure recording is stopped
          // this.switchScene(obs, obs.defaultScene) // Set initial scene
        }
        this.getCurrentScene(obs)
        this.getRecordingStatus(obs)
      }

      // Events
      // Set scene when changed on OBS
      else if (message.d?.eventType === 'CurrentProgramSceneChanged' && message.d?.eventData) {
        obs.currentScene = message.d.eventData.sceneName
      }
      // Is recording
      else if (message.d?.eventType === 'RecordStateChanged' && message.d?.eventData) {
        obs.isRecording = message.d.eventData.outputActive
      }

      // Request responses
      // Get current scene on OBS (initial)
      else if (message.d?.requestType === 'GetCurrentProgramScene' && message.d?.responseData) {
        obs.currentScene = message.d.responseData.currentProgramSceneName
      }
      // Get recording status
      else if (message.d?.requestType === 'GetRecordStatus' && message.d?.responseData) {
        obs.isRecording = message.d.responseData.outputActive
      }
      // Get virtual cam
      else if (message.d?.requestType === 'GetVirtualCamStatus' && message.d?.responseData) {
        if (!message.d.responseData.outputActive) {
          this.startVirtualCam()
        }
      }
    },

    getCurrentScene(obs) {
      this.wsSendMessage(obs, {
        op: 6,
        d: {
          requestType: 'GetCurrentProgramScene',
          requestId: 'getCurrentScene'
        }
      })
      // Expect a response in wsHandleMessage
    },

    switchScene(obs, sceneName) {
      this.wsSendMessage(obs, {
        op: 6,
        d: {
          requestType: 'SetCurrentProgramScene',
          requestId: 'switchScene',
          requestData: { sceneName }
        }
      })
      obs.currentScene = sceneName
    },

    // Recording
    toggleRecording() {
      if (this.obsServer['main'].isRecording) {
        this.stopRecording()
      } else {
        this.startRecording()
      }
    },

    stopRecording() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StopRecord',
          requestId: 'stop_record'
        }
      })
      this.obsServer['main'].isRecording = false
    },

    startRecording() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StartRecord',
          requestId: 'start_record'
        }
      })
      this.obsServer['main'].isRecording = true
    },

    getRecordingStatus(obs) {
      this.wsSendMessage(obs, {
        op: 6,
        d: {
          requestType: 'GetRecordStatus',
          requestId: 'getRecordingStatus'
        }
      })
      // Expect a response in wsHandleMessage
    },

    // Virtual Cam
    getVirtualCamStatus() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'GetVirtualCamStatus',
          requestId: 'getVirtualCamStatus'
        }
      })
      // Expect a response in wsHandleMessage
    },

    startVirtualCam() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StartVirtualCam',
          requestId: 'start_virtual_cam'
        }
      })
      this.obsServer['main'].isVirtualCamOn = true
    }
  }
}
</script>
