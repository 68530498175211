<template>
  <button
    class="text-center transition-colors font-bold py-3 rounded-xl min-w-button flex items-center justify-center text-base"
    :class="{
      'bg-button-red text-white': (color === 'red' && !loading) || (loading && loadingBackground === 'red'),
      'bg-gray-900 bg-opacity-90 text-gray-400':
        (color === 'gray' && !loading) || (loading && loadingBackground === 'gray'),
      'bg-button-inactive text-button-inactive':
        (color === 'inactive' && !loading) || (loading && loadingBackground === 'inactive'),
      'bg-button-primary text-white': (color === 'primary' && !loading) || (loading && loadingBackground === 'primary'),
      'bg-gray-900 bg-opacity-90':
        (color === 'bginactive' && !loading) || (loading && loadingBackground === 'bginactive'),
      'bg-gray-900 bg-opacity-90 text-primary':
        (color === 'active' && !loading) || (loading && loadingBackground === 'active')
    }"
  >
    <fw-icon-loading v-if="loading" class="w-10 h-10 text-white" />
    <slot v-else></slot>
  </button>
</template>
<script>
export default {
  name: 'ButtonTablet',
  props: {
    color: {
      type: String,
      default: 'inactive' //active, inactive, red, gray, primary
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingBackground: {
      type: String,
      default: 'gray'
    }
  }
}
</script>

<style scoped>
.bg-button-red {
  background-color: #ef6161;
}
.bg-button-gray {
  background-color: #333;
}
.bg-button-inactive {
  background-color: #00000035;
}
.text-button-inactive {
  color: #00000052;
}
.bg-button-primary {
  background-color: #38c3b1;
}
.min-w-button {
  min-width: 4.5rem;
}
</style>
