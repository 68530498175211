<template>
  <div class="flex gap-5 h-full text-white">
    <div class="p-5 flex flex-col w-1/2 text-lg">
      <div class="flex-1">
        <div class="font-mono font-bold text-4xl mb-2">
          {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
        </div>
        <v-clamp autoresize :max-lines="3" class="text-xl font-semibold">
          {{ !isClassEdition ? meeting.title : meeting.context.unit.title }}
        </v-clamp>
      </div>
      <PanelMeetingButtonsGroup class="pb-5">
        <template #header> Áudio dos participantes </template>
        <ButtonTablet
          :color="allowMicrophones ? 'active' : 'gray'"
          class="flex-1 h-16"
          :loading="loading.mics_and_share"
          @click.native="toggleMicsAndShare()"
        >
          Microfones livres
        </ButtonTablet>
        <ButtonTablet
          class="flex-1 h-16"
          :color="silenceActive ? 'active' : 'gray'"
          :loading="loading.silence_all"
          @click.native="muteAll"
        >
          Silenciar todos
        </ButtonTablet>
      </PanelMeetingButtonsGroup>
      <PanelMeetingButtonsGroup class="pb-5" :number-columns="1">
        <template #header> Modo da sessão </template>
        <div class="flex w-full">
          <ButtonTablet
            v-if="!loading.changingMode"
            class="flex-1 rounded-r-none h-16"
            :disabled="loading.changingMode"
            :color="showToEveryone ? 'active' : 'gray'"
            @click.native="changeMode('set-mode-meeting')"
          >
            Normal
          </ButtonTablet>
          <ButtonTablet
            v-if="!loading.changingMode"
            class="flex-1 rounded-l-none h-16"
            :disabled="loading.changingMode"
            :color="!showToEveryone ? 'active' : 'gray'"
            @click.native="changeMode('set-mode-webinar')"
          >
            Webinar
          </ButtonTablet>
          <ButtonTablet v-if="loading.changingMode" class="flex-1 h-16" :loading="loading.changingMode" :color="'gray'">
          </ButtonTablet>
        </div>
      </PanelMeetingButtonsGroup>
      <PanelMeetingButtonsGroup class="pb-5">
        <template #header> Autorizações </template>
        <ButtonTablet
          class="flex-1 h-16"
          :color="instanceCanJoin ? 'active' : 'gray'"
          :loading="loading.can_join"
          @click.native="toggleJoin()"
        >
          Entrada livre
        </ButtonTablet>
        <ButtonTablet
          class="flex-1 h-16"
          :color="allowAnonymousWithoutCode ? 'active' : 'gray'"
          :loading="loading.anonymous"
          @click.native="toggleAnonymousWithoutCode()"
        >
          Convidados
        </ButtonTablet>
      </PanelMeetingButtonsGroup>
    </div>

    <div class="w-1/2 flex-shrink-0 rounded-3xl bg-panel px-5 pb-5 pt-3 flex flex-col gap-5 mr-5 mb-5 relative">
      <div class="flex justify-between gap-5">
        <div>
          <fw-button :type="playAudio ? 'link-primary' : 'link-muted'" @click.native="$emit('toogle-audio')">
            <fw-icon-volume-lines v-if="playAudio" class="h-7 w-7 text-primary"></fw-icon-volume-lines>
            <fw-icon-volume-mute v-else class="h-7 w-7"></fw-icon-volume-mute>
          </fw-button>
        </div>
        <div class="flex items-center gap-1">
          <fw-button
            v-if="attendee.withRole('camera_allowed')"
            :type="cameraQuality == 'SD' ? 'link' : 'link-muted'"
            @click.native="changeVideoQuality('SD')"
            ><span class="text-xl">SD</span></fw-button
          >
          <fw-button
            v-if="attendee.withRole('camera_allowed')"
            :type="cameraQuality == 'HD' ? 'link' : 'link-muted'"
            @click.native="changeVideoQuality('HD')"
            ><span class="text-xl">HD</span></fw-button
          >
          <fw-button
            v-if="attendee.withRole('camera_allowed')"
            :type="cameraQuality == 'FHD' ? 'link' : 'link-muted'"
            class="hidden lg:flex"
            @click.native="changeVideoQuality('FHD')"
            ><span class="text-xl">FHD</span></fw-button
          >
        </div>
        <div class="opacity-70">
          <fw-button type="transparent" @click.native="$emit('change-settings')">
            <fw-icon-settings class="h-7 w-7"></fw-icon-settings>
          </fw-button>
        </div>
      </div>
      <div class="text-center flex flex-col gap-1">
        <div class="text-4xl font-bold h-10">
          {{ duration }}
        </div>
        <div class="font-semibold text-xs opacity-50">Iniciou às {{ startedAt }}</div>
      </div>
      <div class="flex-1">
        <div class="grid grid-cols-2 gap-4">
          <ButtonTablet
            class="flex justify-center gap-2 items-center h-24"
            :loading="camera.loading || loading.camera"
            @click.native="toggleInput(camera, 'camera')"
          >
            <fw-icon-camera-full v-if="camera.active" class="w-[3.75rem] h-[3.75rem] text-primary" />
            <fw-icon-camera-off v-else class="w-[3.75rem] h-[3.75rem] text-red-500" />
          </ButtonTablet>

          <ButtonTablet
            class="flex justify-center gap-2 items-center h-24"
            :loading="audio.loading || loading.microphone"
            @click.native="toggleInput(audio, 'microphone')"
          >
            <fw-icon-mic-full v-if="audio.active" class="w-14 h-14 text-primary" />
            <fw-icon-mic-off v-else class="w-14 h-14 text-red-500" />
          </ButtonTablet>

          <ButtonTablet
            v-for="(atend, a) in screenShareDevicesList"
            :key="atend.key"
            color="bginactive"
            class="flex-1 flex h-24 flex-col items-center justify-center gap-1 text-3xl"
            :loading="loading['screenshare' + a]"
            :class="{ 'text-primary': deviceWithStreams(atend), 'text-gray-500': !deviceWithStreams(atend) }"
            @click.native="toggleDeviceVisibility(atend, a, 'screenshare')"
          >
            <span class="flex items-center justify-center gap-2 text-4xl"
              ><fw-icon-computer class="w-12 h-12" /> {{ a + 1 }}</span
            >
            <span class="text-sm">Partilha</span>
          </ButtonTablet>
          <ButtonTablet
            v-for="(atend, a) in extraCameraDevicesList"
            :key="atend.key"
            color="bginactive"
            class="flex-1 flex flex-col h-24 items-center justify-center gap-1 text-3xl"
            :loading="loading['extra_camera' + a]"
            :class="{ 'text-primary': deviceWithStreams(atend), 'text-gray-500': !deviceWithStreams(atend) }"
            @click.native="toggleDeviceVisibility(atend, a, 'extra_camera')"
          >
            <span class="flex items-center justify-center gap-2 text-4xl"
              ><fw-icon-live class="w-12 h-12" /> {{ a + 1 }}</span
            >
            <span class="text-sm line-clamp-1">{{ atend?.name.replace('[video]', '') }}</span>
          </ButtonTablet>
        </div>
        <div class="flex w-full mt-4">
          <ButtonTablet
            color="bginactive"
            class="flex-1 flex h-20 items-center justify-center gap-2 text-4xl leading-1 rounded-r-none"
            :class="{ 'opacity-50': volume <= 0.1 }"
            @click.native="changeVolume(-0.1)"
          >
            -
          </ButtonTablet>
          <div
            class="flex-1 flex h-20 items-center relative justify-center text-center text-2xl flex-col bg-button-inactive"
          >
            <div class="font-bold text-sm mb-0.5 opacity-40 absolute bottom-1.5">volume</div>
            {{ volumePercentage }}
          </div>
          <ButtonTablet
            color="bginactive"
            class="flex-1 flex h-20 items-center justify-center gap-2 text-4xl leading-1 rounded-l-none"
            :class="{ 'opacity-50': volume >= 1 }"
            :disabled="volume >= 1"
            @click.native="changeVolume(0.1)"
          >
            +
          </ButtonTablet>
        </div>
      </div>
      <b-button class="has-text-danger text-lg" @click="$emit('leave-meeting')"> Sair da sessão </b-button>
      <ButtonTablet
        v-if="attendee.withRole('can_end')"
        class="text-lg"
        color="red"
        @click.native="$emit('end-meeting')"
      >
        Terminar aula
      </ButtonTablet>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
const ALLOWED_ROLES = [
  'audio_allowed',
  'camera_allowed',
  'sharescreen_allowed',
  'plugin_allowed',
  'show_to_everyone',
  'can_join'
]
import ButtonTablet from '@/components/buttons/ButtonTablet'
import PanelMeetingButtonsGroup from '@/components/panels/PanelMeetingButtonsGroup'
import Vue from 'vue'
export default {
  name: 'PanelMeetingControls',
  components: {
    ButtonTablet,
    PanelMeetingButtonsGroup
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    roomAttendees: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      default: 'pt'
    },
    isRunning: {
      type: Boolean,
      required: true
    },
    duration: {
      type: [Number, String]
    },
    attendee: {
      type: Object,
      required: true
    },
    camera: {
      type: Object,
      required: true
    },
    janus: {
      type: Object
    },
    instance: {
      type: Object,
      required: true
    },
    cameraOptions: {
      type: Array,
      default: () => []
    },
    audio: {
      type: Object,
      required: true
    },
    audioInputOptions: {
      type: Array,
      default: () => []
    },
    isClassEdition: {
      type: Boolean,
      default: false
    },
    withJanus: {
      type: Boolean,
      default: false
    },
    startedAt: {
      type: String,
      default: '--:--'
    },
    inputDelay: {
      type: Number,
      default: 1000
    },
    volume: {
      type: Number,
      default: 1
    },
    playAudio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const roles = {}
    this.setRoles(this.instance, roles)
    let allowAnonymous = false
    let allowAnonymousWithoutCode = false
    let anonymousCode = null
    console.log('MEETING', this.meeting)
    if (this.meeting && this.meeting.anonymous) {
      allowAnonymous = this.meeting.anonymous.allowed
      anonymousCode = this.meeting.anonymous.code
      allowAnonymousWithoutCode = allowAnonymous && anonymousCode == null
    }

    return {
      roles: roles,
      podsOverflow: true,
      allowAnonymous: allowAnonymous,
      allowAnonymousWithoutCode: allowAnonymousWithoutCode,
      anonymousCode: anonymousCode,
      anonymousCodeError: null,
      controlsOveflow: false,
      loading: {
        camera: false,
        microphone: false,
        // Add some screens and extra cameras
        screenshare1: false,
        screenshare2: false,
        extra_camera1: false,
        extra_camera2: false,
        sessionmode_normal: false,
        sessionmode_webinar: false,
        can_join: false,
        anonymous: false,
        silence_all: false,
        mics_and_share: false,
        changingMode: false
      }, //microphone, camera, screen1, screen2
      silenceActive: false,
      silenceTimer: null,
      cameraQuality: 'HD'
    }
  },
  computed: {
    volumePercentage() {
      return Math.floor(this.volume * 100) + '%'
    },
    isDebug() {
      return localStorage.getItem('debug')
    },
    isTabletOrMobile() {
      return this.isMobile || this.$device.isTablet()
    },
    showToEveryone() {
      return this.instance && this.instance.withRole('show_to_everyone')
    },
    allowMicrophones() {
      return this.instance && this.instance.withRole('audio_allowed')
    },
    instanceCanJoin() {
      return this.instance && this.instance.withRole('can_join')
    },
    screenShareDevicesList() {
      return this.roomAttendees
        .filter(
          i =>
            i.room_data &&
            i.room_data.source_type == 'screenshare' &&
            i.room_data.source_name.startsWith('[screenshare]')
        )
        .sort((a, b) => a.room_data.source_name.localeCompare(b.room_data.source_name))
    },
    extraCameraDevicesList() {
      return this.roomAttendees
        .filter(
          i => i.room_data && i.room_data.source_type == 'screenshare' && i.room_data.source_name.startsWith('[video]')
        )
        .sort((a, b) => a.room_data.source_name.localeCompare(b.room_data.source_name))
    }
  },
  watch: {
    instance: {
      handler: function(instance) {
        this.setRoles(instance, this.roles)
      },
      deep: true
    }
  },
  mounted() {
    if (localStorage.getItem('video_quality')) {
      this.cameraQuality = localStorage.getItem('video_quality')
    } else {
      localStorage.setItem('video_quality', 'HD')
      localStorage.setItem('device.video.hd', true)
    }
    Vue.nextTick(() => {
      this.verifyControlsOverflow()
    })
  },
  methods: {
    changeVideoQuality(quality) {
      localStorage.setItem('video_quality', quality)
      this.cameraQuality = quality
      if (quality === 'SD') {
        localStorage.removeItem('device.video.hd')
        localStorage.removeItem('device.video.fhd')
      } else if (quality === 'FHD') {
        localStorage.setItem('device.video.hd', true)
        localStorage.setItem('device.video.fhd', true)
        this.$emit('toggle-hd-mode')
      } else {
        localStorage.setItem('device.video.hd', true)
        localStorage.removeItem('device.video.fhd')
        this.$emit('toggle-hd-mode')
      }

      if ((quality === 'SD' && this.attendee.with_hd) || (quality !== 'SD' && !this.attendee.with_hd)) {
        this.$emit('toggle-hd-mode')
      }
    },
    changeVolume(change) {
      let newVolume = this.volume + change
      if (newVolume < 0.1) newVolume = 0.1
      if (newVolume > 1) newVolume = 1
      this.$emit('change-volume', newVolume)
    },
    muteAll() {
      if (this.loading['silence_all']) return
      this.loading['silence_all'] = true
      this.$emit('mute-all')
      this.silenceActivated()
      this.$buefy.snackbar.open({
        message: `Os participantes foram silenciados.`,
        type: 'is-primary',
        position: 'is-top-right',
        duration: 5000
      })
      setTimeout(() => {
        this.loading['silence_all'] = false
      }, this.inputDelay)
    },
    silenceActivated() {
      this.silenceActive = true
      if (this.silenceTimer) clearTimeout(this.silenceTimer)
      this.silenceTimer = setTimeout(() => {
        this.silenceActive = false
      }, 3000)
    },
    toggleMicsAndShare() {
      if (!this.loading.mics_and_share) {
        this.loading.mics_and_share = true
        this.$emit('set-permissions', {
          audio_allowed: !this.allowMicrophones,
          sharescreen_allowed: !this.allowMicrophones
        })
        //change loading to false after 1 second
        setTimeout(() => {
          this.loading.mics_and_share = false
        }, this.inputDelay)
      }
    },
    toggleJoin() {
      if (!this.loading.can_join) {
        this.loading.can_join = true
        this.$emit('set-permissions', { can_join: !this.instanceCanJoin })
        //change loading to false after 1 second
        setTimeout(() => {
          this.loading.can_join = false
        }, this.inputDelay)
      }
    },
    toggleInput(input, type) {
      if (!this.loading[type]) {
        this.loading[type] = true
        input.toggle()
        //change loading to false after 1 second
        setTimeout(() => {
          this.loading[type] = false
        }, this.inputDelay)
      }
    },
    toggleDeviceVisibility(device, i, type = 'screenshare') {
      if (!this.loading[type + i]) {
        this.loading[type + i] = true
        this.$emit('toogle-device-visibility', device)
        //change loading to false after 1 second
        setTimeout(() => {
          this.loading[type + i] = false
        }, this.inputDelay)
      }
    },
    changeMode(mode) {
      this.loading.changingMode = true
      this.$emit(mode)
      setTimeout(() => {
        this.loading.changingMode = false
      }, this.inputDelay)
    },
    setRoles(instance, roles) {
      for (let key of ALLOWED_ROLES) {
        roles[key] = instance.withRole(key)
      }
    },
    async updateAnonymous() {
      let meeting
      try {
        meeting = await ServiceMeetings.updateMeetingAnonymous(this.meeting.key, this.allowAnonymousWithoutCode, null)
      } catch (error) {
        this.anonymousCodeError = utils.errors(error).getTranslated()
        if (!this.anonymousCodeError) {
          throw error
        } else {
          return
        }
      }

      console.info(`Anonymous config updated allowed:${this.allowAnonymous} code:${this.anonymousCode}`)
      this.anonymousCodeError = null
      this.meeting.anonymous = meeting.anonymous
    },
    toggleAnonymousWithoutCode() {
      if (this.loading.anonymous) return
      this.loading.anonymous = true
      this.allowAnonymousWithoutCode = !this.allowAnonymousWithoutCode
      Vue.nextTick(() => {
        this.updateAnonymous()
      })
      setTimeout(() => {
        this.loading.anonymous = false
      }, this.inputDelay)
    },
    deviceWithStreams(attendee) {
      return (
        attendee.withRole('show_to_everyone') ||
        //attendee.withRole('audio_allowed') ||
        attendee.withRole('camera_allowed') ||
        attendee.withRole('sharescreen_allowed')
      )
    },
    verifyControlsOverflow() {
      let container = this.$refs.controls
      if (container) {
        this.controlsOveflow = container.scrollHeight > container.clientHeight
        if (this.controlsOveflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.controlsOveflow = false
            } else {
              this.controlsOveflow = true
            }
          })
        }
      } else {
        this.controlsOveflow = false
      }
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "running": "A decorrer"
    },
    "en": {
      "running": "Running"
    }
  }
</i18n>
<style scoped>
.bg-inactive-icon {
  background-color: #00000052;
}
.bg-button-inactive {
  background-color: #00000035;
}
</style>
