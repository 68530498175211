<template>
  <div class="flex-shrink-0 grow flex flex-col gap-5 w-2/5">
    <div
      v-if="janus && camera"
      class="video-stream overflow-hidden flex-shrink-0 relative bg-gray-800"
      :style="{ height: '480px' }"
    >
      <div v-if="camera.active" class="absolute top-5 right-5 font-bold bg-opacity-20 text-white text-2xl">
        <div class="relative pr-11 pl-1">
          Live
          <div class="h-3 w-3 rounded-full absolute top-0.5 right-5 bg-white animate-pulse"></div>
        </div>
      </div>
      <div v-else class="absolute w-full h-full flex flex-col gap-5 items-center justify-center bg-gray-900">
        <div><fw-icon-camera-off class="w-40 h-40 text-gray-600" /></div>
        <div class="text-gray-500 text-lg font-semibold">Vídeo desligado</div>
      </div>
      <video
        id="video-my-webcam"
        class="webcam w-full object-contain h-full"
        :class="{ 'video-off': !camera.active }"
        autoplay
        playsinline
        muted="muted"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PanelMeetingCamera',

  props: {
    janus: {
      type: Object,
      default: null
    },
    camera: {
      type: Object,
      default: null
    }
  }
}
</script>
