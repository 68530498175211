<template>
  <LayoutDashboardDefault v-if="meetingNotFound">
    <div class="h-full flex flex-col items-center justify-center flex-1 text-white text-opacity-70">
      <div class="has-margin-medium">
        <faicon class="fill-current" icon="heart-broken" size="3x"></faicon>
      </div>
      <div class="has-text-tiny">Erro 404.</div>
      <h1 class="is-size-2 text-white">A sala não existe</h1>
      <div class="has-margin-top-large text-gray-600">
        <fw-button type="glass" @click.native="$router.go(-1)">Voltar à página anterior</fw-button>
      </div>
    </div>
  </LayoutDashboardDefault>
  <LayoutDashboardDefault v-else :class="{ 'is-sharing-screen': screenShare.active }" :dark="isRunning" paddingless>
    <div v-if="!isRunning" class="h-full flex-1 flex items-center justify-center">
      <HallMeeting
        :loading="loading"
        :is-running="isRunning"
        :meeting="meeting"
        :audio="audio"
        :camera="camera"
        :is-meeting-owner="isMeetingOwner"
        :is-class-edition="isClassEdition"
        :waiting-to-be-accepted="waitingToBeAccepted"
        :enter-request-rejected="enterRequestRejected"
        :start-with-audio="startWithAudio"
        :start-with-camera="startWithCamera"
        @toggle-mirror-video="toggleMirrorVideo"
        @toggle-audio-start-option="startWithAudio = !startWithAudio"
        @toggle-camera-start-option="startWithCamera = !startWithCamera"
        @start-meeting="startMeeting"
        @personalize-meeting-key="ModalPersonalizeMeetingKeyIsActive = true"
      />
    </div>

    <PanelMeetingPods
      v-if="isRunning"
      class="text-white p-5"
      :view="tab"
      :columns="4"
      :minimum-padding="true"
      @click="registerInteraction()"
    >
      <template #header>
        <div v-if="meeting" class="flex flex-col gap-5 pr-2">
          <div class="flex gap-5 items-center">
            <div class="flex-1 flex items-center gap-1 md:gap-3">
              <AppLogo class="text-white" :size="'lg'" />
            </div>
            <div v-if="instance.recording == 'running' || instance.recording == 'paused'">
              <b-tooltip
                :label="
                  instance.recording == 'paused'
                    ? 'A gravação desta sessão está em pausa.'
                    : 'Esta sessão está a ser gravada.'
                "
                :type="instance.recording == 'paused' ? 'is-dark' : 'is-danger'"
                multilined
                position="is-left"
                :animated="false"
              >
                <faicon
                  icon="circle"
                  :class="{
                    'text-red-500 animate-flicker': instance.recording == 'running',
                    'text-white': instance.recording == 'paused'
                  }"
                ></faicon>
              </b-tooltip>
            </div>
            <div v-if="withNetworkIssues || camera.withCPUIssues || screenShare.withCPUIssues">
              <fw-button
                type="simple"
                class="text-red-500 bg-red-300 bg-opacity-10 animate-pulse"
                @click.native="toggleQualityModal()"
              >
                Ligação instável
              </fw-button>
            </div>

            <div class="flex font-bold items-center text-3xl">
              <fw-icon-clock-line class="w-8 h-8 mr-2" />
              {{ currentHour }}
              <span class="animate-pulse">:</span>
              {{ currentMinutes }}
            </div>
          </div>
          <div class="flex gap-9 items-center">
            <ButtonTab
              v-if="waitingAttendees.length > 0"
              :active="tab == 'waiting'"
              :badge="waitingAttendees.length"
              @click="tab = 'waiting'"
              >À espera para entrar</ButtonTab
            >
            <ButtonTab :active="tab == 'handsup'" :badge="attendeesHandsUp.length" @click="tab = 'handsup'"
              >A pedir a palavra</ButtonTab
            >
            <ButtonTab :active="tab == 'messages'" :badge="unreadChatMessages" @click="tab = 'messages'"
              >Canal</ButtonTab
            >
            <ButtonTab :active="tab == 'participants'" :badge="visiblePods.length" @click="tab = 'participants'"
              >Participantes</ButtonTab
            >
          </div>
        </div>
      </template>
      <template #main>
        <PanelHandsup
          v-if="tab === 'handsup'"
          :pods="attendeesHandsUpObjects"
          :meeting="meeting"
          :free-microphones="instance.withRole('audio_allowed')"
          @reload="reloadPage()"
          @remove="removeAttendeeHand"
        ></PanelHandsup>
        <PanelParticipants
          v-else-if="tab === 'participants'"
          :pods="visiblePods"
          :attendees="attendees"
          :as-webinar="asWebinar"
          :can-promote="canPromote"
          @toggle-promote="togglePromote"
          @kick-participant="kickAttendee"
        ></PanelParticipants>
        <PanelMessages
          v-else-if="tab === 'messages'"
          :all-chat-users="allChatUsers"
          :chat-active="chatActive"
          :chats="chats"
          @load-chat="loadChat"
          @load-main-chat="loadMainChat"
          @unload-chat="unloadChat"
        />
        <PanelWaitingAttendees
          v-else-if="tab === 'waiting'"
          :pods="waitingAttendees"
          @accept="acceptParticipants"
          @reject="rejectParticipants"
          @open-remove-attendee-modal="openRemoveAttendeeModal"
        />
      </template>
    </PanelMeetingPods>

    <PanelMeetingCamera v-if="isRunning" :camera="camera" :janus="janus">
      <PanelMeetingControls
        :room-attendees="roomAttendees"
        :is-running="isRunning"
        :duration.sync="duration"
        :with-janus="withJanus"
        :attendee="attendee"
        :janus="janus"
        :instance="instance"
        :camera-options="cameraOptions"
        :camera="camera"
        :audio="audio"
        :audio-input-options="audioInputOptions"
        :is-class-edition="isClassEdition"
        :meeting="meeting"
        :started-at="startedAt"
        :volume="volume"
        :play-audio="playAudio"
        @toogle-audio="playAudio = !playAudio"
        @set-mode-meeting="setModeMeeting(true)"
        @set-mode-webinar="setModeWebinar(false)"
        @toogle-device-visibility="toggleDeviceVisibility($event)"
        @mute-all="muteAll"
        @toggle-hd-mode="toggleHdMode"
        @set-permissions="setPermissions($event)"
        @end-meeting="endMeetingEvent"
        @leave-meeting="leaveMeeting"
        @change-settings="toggleMediaSelection"
        @change-volume="saveAndChangeVolume"
      ></PanelMeetingControls>
    </PanelMeetingCamera>

    <div v-if="isDebug" class="bg-white p-4 fixed bottom-0 left-0">
      DEBUG PARTICIPANTES ({{ visiblePods.length }}):<br />
      <div v-for="pod in visiblePods" :key="pod.id" class="mb-2">
        {{ pod.name }}
      </div>
    </div>

    <template #modals>
      <ModalRequestEnter
        v-if="ModalRequestToEnterIsActive"
        :waiting-attendees="waitingAttendees"
        :auto-accept="instanceCanJoin"
        @close="ModalRequestToEnterIsActive = false"
        @accept-participants="acceptParticipants"
        @reject-participants="rejectParticipants"
        @open-remove-attendee-modal="openRemoveAttendeeModal"
      />
      <ModalAttendeePermissions
        :show="permissionsAttendeeModalActive"
        :attendee="permissionsAttendee"
        :meeting="meeting"
        @close="resetPermissionsModal"
        @reload-page="reloadPage"
      />
      <ModalRemoveAttendee
        v-if="ModalRemoveAttendee"
        :data="ModalRemoveAttendee"
        :is-exam="isExam"
        :ask-reject-message="false"
        @call="callRemoveAttendeeModal"
        @close="closeRemoveAttendeeModal"
      />
      <ModalMediaSelection
        v-if="instance && showMediaSelection"
        :show-media-selection="showMediaSelection"
        :audio="audio"
        :camera="camera"
        :audio-output="audioOutput"
        :devices-error="devicesError"
        :camera-options="cameraOptions"
        :audio-input-options="audioInputOptions"
        :audio-output-options="audioOutputOptions"
        @toggle-media-selection="toggleMediaSelection"
        @toggle-mirror-video="toggleMirrorVideo"
      />

      <div id="audio-streams" class="is-hidden"></div>
    </template>
  </LayoutDashboardDefault>
</template>

<script>
import BaseLive from '@/fw-modules/fw-meetings-vue/mixins/BaseLive'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import JanusLive from '@/fw-modules/fw-meetings-vue/mixins/JanusLive'
import JanusSelfLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSelfLive'
import JanusSubscribersLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSubscribersLive'
import PodsLive from '@/fw-modules/fw-meetings-vue/mixins/PodsLive'
import ModalAttendeePermissions from '@/fw-modules/fw-meetings-vue/components/modals/ModalAttendeePermissions'
import ModalRequestEnter from '@/components/modals/ModalRequestEnter'
import ModalRemoveAttendee from '@/fw-modules/fw-meetings-vue/components/modals/ModalRemoveAttendee'
//import BlockWaitingAttendees from '@/fw-modules/fw-meetings-vue/components/blocks/BlockWaitingAttendees'
// import HallMeetingBasic from '@/components/panels/HallMeetingBasic'
import HallMeeting from '@/fw-modules/fw-meetings-vue/components/panels/HallMeeting'
//import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
//NEW IMPORTS
import LayoutDashboardDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDashboardDefault'
import PanelMeetingControls from '@/components/panels/PanelMeetingControls.vue'
import PanelMeetingCamera from '@/components/panels/PanelMeetingCamera.vue'
import ModalMediaSelection from '@/fw-modules/fw-meetings-vue/components/modals/ModalMediaSelection'
import PanelMeetingPods from '@/components/panels/PanelMeetingPods'
import AppLogo from '@/components/AppLogo'
//import PodCard from '@/components/cards/PodCard'
import PanelParticipants from '@/components/panels/PanelParticipants'
import PanelHandsup from '@/components/panels/PanelHandsup'
import PanelMessages from '@/components/panels/PanelMessages'
import PanelWaitingAttendees from '@/components/panels/PanelWaitingAttendees'
import ButtonTab from '@/components/buttons/ButtonTab'
import ServiceSettings from '../fw-modules/fw-core-vue/id/services/ServiceSettings'
export default {
  components: {
    //LayoutDefault,
    //BlockWaitingAttendees,
    //BlockCamera,
    //BlockAudio,
    HallMeeting,
    ModalRequestEnter,
    ModalAttendeePermissions,
    ModalRemoveAttendee,
    //New imports
    LayoutDashboardDefault,
    PanelMeetingControls,
    PanelMeetingCamera,
    ModalMediaSelection,
    PanelMeetingPods,
    AppLogo,
    //PodCard,
    PanelParticipants,
    PanelHandsup,
    PanelMessages,
    ButtonTab,
    PanelWaitingAttendees
  },

  mixins: [BaseLive, ChatLive, JanusLive, JanusSelfLive, JanusSubscribersLive, PodsLive],
  data() {
    return {
      tab: 'handsup',
      currentHour: '--',
      currentMinutes: '--',
      clocktimer: null,
      startedAt: '--:--',
      lastInteraction: null,
      playAudio: true,
      lastAudioPlay: null,
      newHandsUpAudio: new Audio('/sounds/wrong-answer-129254.mp3'),
      newWaitingEntranceAudio: new Audio('/sounds/wrong-answer-129254.mp3'),
      previousValues: {
        handsup: 0,
        waiting: 0
      }
    }
  },
  computed: {
    unreadChatMessages() {
      return parseInt(this.chatAllUnread == '' ? 0 : this.chatAllUnread)
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length - this.attendeesWithaudioPermission
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
    visiblePods() {
      return this.pods.filter(i => i.attendee != null && !i.attendee.withRole('is_room_plugin'))
    },
    isDebug() {
      return localStorage.getItem('debug')
    },
    isTabletOrMobile() {
      return this.isMobile || this.$device.isTablet()
    },
    showToEveryone() {
      return this.instance && this.instance.withRole('show_to_everyone')
    },
    instanceCanJoin() {
      return !!this.instance.withRole('can_join')
    },
    attendeesHandsUpObjects() {
      return this.attendeesHandsUp.map(i => this.attendees[i])
    },
    canPromote() {
      return this.attendee && this.attendee.withRole('can_promote')
    }
  },

  watch: {
    waitingAttendees(val) {
      console.log('waitingAttendees', val)
      if (val.length > this.previousValues.waiting) {
        this.smartAudio(this.newWaitingEntranceAudio)
      }
      this.previousValues.waiting = val.length
    },
    attendeesHandsUp(val) {
      if (val.length > 0) {
        this.smartTab('handsup')
      }
      if (val.length > this.previousValues.handsup) {
        this.smartAudio(this.newHandsUpAudio)
      }
      this.previousValues.handsup = val.length
    },
    unreadChatMessages(val) {
      if (val > 0) {
        this.smartTab('messages')
      }
    },
    isRunning(val) {
      if (val) {
        //start clock and save start time
        this.startClock()
        this.startedAt = this.currentHour + ':' + this.currentMinutes
      }
    }
  },

  mounted() {
    if (this.$device.isiOS) {
      window.addEventListener('resize', this.fix100vhiOSBug)
      this.fix100vhiOSBug()
    }
    window.addEventListener('click', this.registerInteraction)
    this.loadUserSettings()
    /*Vue.nextTick(() => {
      this.verifyPodsOverflow()
    })*/
  },

  beforeDestroy() {
    if (this.$device.isiOS) {
      window.removeEventListener('resize', this.fix100vhiOSBug)
    }
    window.removeEventListener('click', this.registerInteraction)
  },

  methods: {
    saveAndChangeVolume(volume) {
      console.log('saving volume in user settings', volume)
      this.changeVolume(volume)
      ServiceSettings.setSetting('volume', volume)
    },
    loadUserSettings() {
      ServiceSettings.getSetting('volume').then(volume => {
        if (volume.value && parseFloat(volume.value) > 0) {
          console.log('applying user settings volume', volume.value)
          this.changeVolume(parseFloat(volume.value))
        }
      })
    },
    smartAudio(audio) {
      console.log('playing audio', audio)
      if (this.playAudio && (this.lastAudioPlay == null || new Date() - this.lastAudioPlay > 15000)) {
        this.lastAudioPlay = new Date()
        audio.volume = this.volume - this.volume * 0.1
        audio.play()
      }
    },
    smartTab(tab) {
      //change tab only if last interaction was more than 30 seconds ago
      if (this.lastInteraction) {
        let now = new Date()
        let diff = now - this.lastInteraction
        if (diff > 30000) {
          this.tab = tab
        }
      }
    },
    registerInteraction() {
      this.lastInteraction = new Date()
      //console.log('REGISTER INTERACTION', this.lastInteraction)
    },
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit'
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit'
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      if (this.clocktimer) clearInterval(this.clocktimer)
      this.updateTime()
      setInterval(() => {
        this.updateTime()
      }, 1000)
    },
    hasVideo(video) {
      return typeof video !== 'undefined' && video !== null
    },
    /*verifyPodsOverflow() {
      let container = this.$refs.podslist
      if (container) {
        console.log('HAVE PODS CONTAINER', container.scrollHeight, container.clientHeight)
        this.podsOverflow = container.scrollHeight > container.clientHeight
        if (this.podsOverflow) {
          console.log('THERE IS POD OVERFLOW')
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.podsOverflow = false
            } else {
              this.podsOverflow = true
            }
          })
        }
      } else {
        this.podsOverflow = false
      }
    },*/
    endMeetingEvent() {
      console.log('END MEETING')
      this.endMeeting()
    },

    setPermissions(roles) {
      const newRoles = {}
      for (const [name, value] of Object.entries(roles)) {
        const key = ServiceMeetings.getRole(name)
        newRoles[key] = !!value
      }
      if (Object.keys(newRoles).length) {
        this.updateRoles(newRoles, false)
      }
    },

    resetPermissionsModal() {
      this.bulkPermissionsModalActive = false
      this.permissionsAttendeeModalActive = false
      this.permissionsAttendee = null
    },

    fix100vhiOSBug() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },

    openRemoveAttendeeModal(data) {
      this.ModalRemoveAttendee = data
    },
    async callRemoveAttendeeModal(data) {
      await this.ModalRemoveAttendee.callback(data)
      this.closeRemoveAttendeeModal()
    },
    closeRemoveAttendeeModal() {
      this.ModalRemoveAttendee = null
    },

    deviceWithStreams(attendee) {
      return (
        attendee.withRole('show_to_everyone') ||
        attendee.withRole('audio_allowed') ||
        attendee.withRole('camera_allowed') ||
        attendee.withRole('sharescreen_allowed')
      )
    },
    async toggleDeviceVisibility(attendee) {
      const roles = {}
      const newRole = !this.deviceWithStreams(attendee)
      roles[ServiceMeetings.getRole('show_to_everyone')] = newRole
      roles[ServiceMeetings.getRole('audio_allowed')] = newRole
      roles[ServiceMeetings.getRole('camera_allowed')] = newRole
      roles[ServiceMeetings.getRole('sharescreen_allowed')] = newRole
      await ServiceMeetings.changeAttendeeRoles(this.meeting.key, attendee.key, roles, true)
    },
    getAttendeeName(attendee) {
      if (attendee.name) {
        return attendee.name
      } else if (attendee.user) {
        return attendee.user.name
      } else {
        return attendee.key
      }
    },
    async removeAttendeeHand(attendee) {
      if (!this.instance.withRole('audio_allowed')) {
        this.blockAttendeeAudio(attendee)
      }
      if (!this.instance.withRole('sharescreen_allowed')) {
        this.blockAttendeeScreenShare(attendee)
      }
      this.lowerAttendeeHand(attendee)
    }
  }
}
</script>
