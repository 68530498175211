var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-1"},[_c('div',[_c('fw-label',{attrs:{"size":"xs"}},[_vm._v("Cenários de vídeo")]),_c('div',{staticClass:"relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-4"},[(_vm.obsServer['main'].isConnected)?_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"grid grid-cols-3 gap-4"},_vm._l((_vm.obsServer['main'].scenes),function(scene){return _c('fw-button',{key:scene.name,staticClass:"h-[5.5rem] text-2xl",class:{ 'text-gray-400': _vm.obsServer['main'].currentScene !== scene.name },attrs:{"type":_vm.obsServer['main'].currentScene === scene.name ? 'link' : 'link-muted',"expanded":"","size":"xl"},nativeOn:{"click":function($event){return _vm.switchScene(_vm.obsServer['main'], scene.name)}}},[_vm._v(" "+_vm._s(scene.label)+" ")])}),1)]):_c('div',{staticClass:"text-base text-center py-5",class:{
          'text-gray-600': !_vm.obsServer['main'].error,
          'text-red-600': _vm.obsServer['main'].error
        }},[_vm._v(" "+_vm._s(!_vm.obsServer['main'].error ? 'Desligado' : _vm.obsServer['main'].error)+" ")])])],1),(_vm.usePresentationScreen)?_c('div',[_c('fw-label',{attrs:{"size":"xs"}},[_vm._v("Controlo de ecrã principal")]),_c('div',{staticClass:"relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-4"},[(_vm.obsServer['presentation'].isConnected)?_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"grid grid-cols-3 gap-4"},_vm._l((_vm.obsServer['presentation'].scenes),function(scene){return _c('fw-button',{key:scene.name,class:{ 'text-gray-400': _vm.obsServer['presentation'].currentScene !== scene.name },attrs:{"type":_vm.obsServer['presentation'].currentScene === scene.name ? 'link' : 'link-muted',"expanded":"","size":"xl"},nativeOn:{"click":function($event){return _vm.switchScene(_vm.obsServer['presentation'], scene.name)}}},[_vm._v(" "+_vm._s(scene.label)+" ")])}),1)]):_c('div',{staticClass:"text-base text-center py-5",class:{
          'text-gray-600': !_vm.obsServer['presentation'].error,
          'text-red-600': _vm.obsServer['presentation'].error
        }},[_vm._v(" "+_vm._s(!_vm.obsServer['presentation'].error ? 'Desligado' : _vm.obsServer['presentation'].error)+" ")])])],1):_vm._e(),(_vm.obsServer['main'].isConnected)?_c('div',[_c('fw-label',{attrs:{"size":"xs"}},[_vm._v("Gravação")]),_c('div',[_c('ButtonTablet',{staticClass:"text-base w-full px-5 py-1 h-[4rem]",attrs:{"color":_vm.obsServer['main'].isRecording ? 'red' : 'inactive'},nativeOn:{"click":function($event){return _vm.toggleRecording.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center justify-between gap-2 w-full",class:{
            'text-white animate-pulse': _vm.obsServer['main'].isRecording,
            'text-gray-400': !_vm.obsServer['main'].isRecording
          }},[_c('fw-icon-record-circle',{staticClass:"w-6 h-6"}),_c('span',[_vm._v(_vm._s(_vm.obsServer['main'].isRecording ? 'A gravar...' : 'Gravar'))])],1)])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }